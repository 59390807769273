import { ChangeEvent, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Loading } from "components";
import { useDeleteUserMutation, useUpdateUserMutation } from "services";
import { CompanyId, UUID, User, convertDateFormat } from "helpers";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { setSelectedUser as setStoreSelectedUser } from "services/userSlice";
import { useClickOutside } from "hooks";
import { useAdminManagementContext } from "pages/AdminManagement/utils/AdminManagementContext";
import { AdminTableDataUserType } from "./components/AdminTableDataUserType/AdminTableDataUserType";
import { AdminTableDataUserInfo } from "./components/AdminTableDataUserInfo";
import { AdminTableDataOptions } from "./components/AdminTableDataOptions/AdminTableDataOptions";
import { AdminTableDataDeletingUser } from "./components/AdminTableDataDeletingUser/AdminTableDataDeletingUser";
import { use2FA } from "hooks";

export type DeleteStep = "initial" | "confirmation" | "deleting";
export type StatusState = "initial" | "updating" | "updated";
export type ModalState = "opened" | "closed";
export type Deleting = { id: UUID; company_id: CompanyId };

interface AdminTableDataProps {
  onFlag: (user: User) => void;
  onResendTempPass: (user: User) => void;
  onEdit: (user: User) => void;
}

export const AdminTableData = ({ onFlag, onResendTempPass, onEdit }: AdminTableDataProps) => {
  const dispatch = useAppDispatch();
  const { data, isLoading: isLoadingUsers, isFetching, onFetch, setPage } = useAdminManagementContext();
  const { selectedUser: storeSelectedUser } = useAppSelector((state) => state.userSlice);

  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [quickEditing, setQuickEditing] = useState<UUID>("");
  const [deleting, setDeleting] = useState<Deleting>({ id: "", company_id: "" });
  const [deleteStep, setDeleteStep] = useState<DeleteStep>("initial");
  const [statusState, setStatusState] = useState<StatusState>("initial");
  const [deletingCounter, setDeletingCounter] = useState<number>(5);
  const [isLoading, setIsLoading] = useState<boolean>(isLoadingUsers || isFetching);
  const editElementRef = useRef(null);
  const { trigger2FA, TwoFAModal } = use2FA();

  useEffect(() => {
    setIsLoading(isLoadingUsers || isFetching);
  }, [isLoadingUsers, isFetching]);

  useEffect(() => {
    if (deleteStep === "deleting" && deletingCounter >= 0) {
      const decrementTimer = () => {
        console.log(deletingCounter);
        if (deletingCounter === 0) {
          trigger2FA(async (otcode: number) => {
            const { id: uuid, company_id } = deleting;
            try {
              if (!otcode) {
                console.error("No 2FA code entered");
                return;
              }
              await deleteUser({ uuid, company_id, otcode }).unwrap();
              console.log("User deleted successfully");
              await onFetch();
            } catch (error) {
              console.error("Error deleting user:", error);
            } finally {
              setDeletingCounter(5);
              setDeleteStep("initial");
            }
          });
          setDeletingCounter(5);
          setDeleteStep("initial");
        } else {
          setTimeout(() => {
            setDeletingCounter((sec) => sec - 1);
          }, 1000);
        }
      };
      decrementTimer();
    }
  }, [deleteStep, deletingCounter]);

  const onQuickEdit = (user: User) => {
    setQuickEditing(user.uuid);
    setStatusState("initial");
    setDeleting({ id: "", company_id: "" });
    dispatch(setStoreSelectedUser(user));
  };

  const onSaveQuickEdit = async (user: User) => {
    const { uuid, company_id } = user;
    setIsLoading(true);
    try {
      await updateUser({
        company_id,
        uuid,
        first_name: storeSelectedUser.first_name,
        last_name: storeSelectedUser.last_name,
        email: storeSelectedUser.email,
        phone_number: storeSelectedUser.phone_number,
      }).unwrap();
      await onFetch();
    } catch (error) {
      console.error(error);
    } finally {
      setQuickEditing("");
      setDeleting({ id: "", company_id: "" });
      dispatch(setStoreSelectedUser({}));
      setIsLoading(false);
    }
  };

  const onDelete = (user: User) => {
    const { uuid: id, company_id } = user;
    setDeleting({ id, company_id });
    setDeleteStep("deleting");
    setQuickEditing("");
  };

  const onCancelDeleting = () => {
    setDeleting({ id: "", company_id: "" });
    setDeleteStep("initial");
    setQuickEditing("");
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    dispatch(setStoreSelectedUser({ ...(storeSelectedUser as User), [name]: value }));
  };

  return (
    <>
      {/* The 2FA modal */}
      {TwoFAModal}
      {!!data &&
        data.data.length > 0 &&
        data.data.map((user, index) => {
          const { uuid, first_name, last_name, company_id, phone_number, email, last_login, type } = user;
          return (
            <tr
              ref={quickEditing === uuid ? editElementRef : null}
              key={index}
              className="h-[100px] max-h-[100px] break-words border-y text-center"
            >
              {deleting.id === uuid && deleteStep === "deleting" ? (
                <td className={twMerge("text-center", "bg-error/20")} colSpan={7}>
                  <AdminTableDataDeletingUser
                    user={user}
                    counter={deletingCounter}
                    onCancelDeleting={onCancelDeleting}
                  />
                </td>
              ) : (
                <>
                  <td className={twMerge("text-center", "text-sm", "w-full")}>
                    <div className="w-[200px] space-y-1">
                      <AdminTableDataUserInfo
                        quickEditing={quickEditing}
                        user={user}
                        first={{
                          value: first_name,
                          input: {
                            className: "py-0 text-center text-sm",
                            name: "first_name",
                            value: storeSelectedUser.first_name,
                            onChange: onChangeHandler,
                          },
                        }}
                        second={{
                          value: last_name,
                          input: {
                            className: "py-0 text-center text-sm",
                            name: "last_name",
                            value: storeSelectedUser.last_name,
                            onChange: onChangeHandler,
                          },
                        }}
                      />
                    </div>
                  </td>
                  <td className="w-full text-center text-sm">
                    <div className="w-[200px] space-y-1">{company_id}</div>
                  </td>
                  <td className="w-full text-center text-sm">
                    <div className="w-[200px] space-y-1">
                      <AdminTableDataUserInfo
                        quickEditing={quickEditing}
                        user={user}
                        first={{
                          value: phone_number,
                          input: {
                            className: "py-0 text-center text-sm",
                            name: "phone_number",
                            value: storeSelectedUser.phone_number,
                            onChange: onChangeHandler,
                          },
                        }}
                        second={{
                          value: email,
                          input: {
                            className: "py-0 text-center text-sm",
                            name: "email",
                            value: storeSelectedUser.email,
                            onChange: onChangeHandler,
                          },
                        }}
                      />
                    </div>
                  </td>
                  <td className="w-full text-center text-sm">{convertDateFormat(last_login)}</td>
                  <td className="w-full text-center text-sm">
                    <AdminTableDataUserType
                      deleting={deleting}
                      quickEditing={quickEditing}
                      editProps={{
                        setIsLoading,
                        setStatusState,
                        status: statusState,
                        user,
                      }}
                      deleteProps={{
                        deleteStep,
                        setDeleteStep,
                        setDeleting,
                      }}
                    />
                  </td>
                  <td className="w-full text-center text-sm">{type.name}</td>
                  <td className="w-full text-center text-sm">
                    <AdminTableDataOptions
                      user={user}
                      quickEditing={quickEditing}
                      deleting={deleting}
                      setDeleting={setDeleting}
                      onResendTempPass={onResendTempPass}
                      onEdit={onEdit}
                      onQuickEdit={onQuickEdit}
                      onSaveQuickEdit={onSaveQuickEdit}
                      onDelete={onDelete}
                      onFlag={onFlag}
                    />
                  </td>
                </>
              )}
            </tr>
          );
        })}

      {isLoading && (
        <tr>
          <td>
            <Loading absolute className="inset-0" />
          </td>
        </tr>
      )}
    </>
  );
};
