import { useState } from "react";
import { Auth2FAModal } from "features/Modal/Auth2FAModal";

interface Use2FAHook {
  trigger2FA: (callback: (otcode: number) => Promise<void>) => void;
  TwoFAModal: JSX.Element;
}

export const use2FA = (): Use2FAHook => {
  const [open2FA, setOpen2FA] = useState(false);
  const [onSuccessCallback, setOnSuccessCallback] = useState<(otcode: number) => Promise<void>>();

  const trigger2FA = (callback: (otcode: number) => Promise<void>) => {
    setOnSuccessCallback(() => callback);
    setOpen2FA(true);
  };

  const handleSuccess = async (otcode: number) => {
    if (onSuccessCallback) {
      await onSuccessCallback(otcode);
    }
    setOpen2FA(false);
  };

  const TwoFAModal = (
    <Auth2FAModal open={open2FA} setOpen={setOpen2FA} userId={""} companyId={""} onSuccess={handleSuccess} />
  );

  return { trigger2FA, TwoFAModal };
};
