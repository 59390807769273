import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "helpers";
import { environment } from "environment";

export const deviceApi = createApi({
  reducerPath: "deviceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${environment.dev.baseUrl}/auth/user/mfa`,
    prepareHeaders: (headers) => {
      headers.set("authorization-token", getToken() || "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    registerDevice: builder.mutation<any, RegisterDeviceArgs>({
      query: ({ username, deviceKey, deviceName }) => ({
        url: "/device",
        body: {
          username,
          device_key: deviceKey,
          device_name: deviceName,
        },
      }),
      transformErrorResponse: (error: any) => error.data.apierror,
    }),
    getSMS: builder.query<any, void>({
      query: () => ({
        url: "/force",
        headers: {
          "authorization-token": getToken() || "",
        },
      }),
      transformErrorResponse: (error: any) => error.data.apierror,
    }),
    verifyMfaCode: builder.mutation<number, VerifyMfaCodeArgs>({
      query: ({ sms_code }) => ({
        url: "/verify-code",
        method: "POST",
        body: {
          sms_code,
        },
        headers: {
          "authorization-token": getToken() || "",
        },
      }),
      transformResponse: (data: any) => data.otcode,
      transformErrorResponse: (error: any) => error.data.apierror,
    }),
  }),
});

export const {
  useRegisterDeviceMutation,
  useLazyGetSMSQuery,
  useVerifyMfaCodeMutation,
  usePrefetch: useDevicePrefetch,
} = deviceApi;

interface RegisterDeviceArgs {
  username: string;
  deviceKey: string;
  deviceName: string;
}

interface VerifyMfaCodeArgs {
  sms_code: number;
}
