import { useEffect } from "react";
import { refreshUser, signOut } from "helpers";

export const TokenRefreshProvider = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      refreshUser()
        .then(() => {
          console.log("Token refreshed successfully.");
        })
        .catch((error) => {
          console.error("Error refreshing token:", error);
          signOut();
        });
    }, 480000); // 8 minutes

    return () => clearInterval(refreshInterval);
  }, []);

  return <>{children}</>;
};
