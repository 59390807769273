import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Modal, ModalProps, Message } from "features";
import { Button, CardHeader, InputWithButton, ValidationMessage } from "components";
import { useLazyGetSMSQuery, useVerifyMfaCodeMutation } from "services";

interface Auth2FAModalProps extends ModalProps {
  userId: string;
  companyId: string;
  onSuccess: (otcode: number) => Promise<void>; // Accepts `otcode`
}

interface VerifyCodeResponse {
  data: {
    otcode: number;
  };
}

export const Auth2FAModal = ({ open, setOpen, onSuccess }: Auth2FAModalProps) => {
  const [code, setCode] = useState({ value: "", status: "initial", error: "" });
  const [disabledCode, setDisabledCode] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [verifyCode] = useVerifyMfaCodeMutation<VerifyCodeResponse>();
  const [forceSendSms] = useLazyGetSMSQuery();

  const returnCodeText = code.status === "initial" ? "Confirm" : code.status === "success" ? "Confirmed" : "Try Again";

  const returnButtonVariant = (status: string) =>
    status === "initial" ? "bg-primary" : status === "success" ? "bg-success" : "bg-error";

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setCode((prev) => ({ ...prev, value: event.target.value }));
  };

  const onSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await onVerifyCode(code.value);
  };

  const onSendCode = async () => {
    try {
      await forceSendSms();
      setDisabledCode(false);
    } catch (error) {
      setCode((prev) => ({ ...prev, error: "Failed to send SMS code" }));
      console.error("Error sending SMS code:", error);
    }
  };

  const onVerifyCode = async (codeValue: string) => {
    setCode((prev) => ({ ...prev, status: "success", error: "" }));
    try {
      const otcode = await verifyCode({ sms_code: parseInt(codeValue) }).unwrap();
      console.log("OT Code:", otcode);
      setCode((prev) => ({ ...prev, status: "success", error: "" }));
      await onSuccess(otcode);
      setOpen(false);
    } catch (error) {
      setCode((prev) => ({ ...prev, error: "Failed to verify code" }));
      console.error("Error verifying code:", error);
    }
  };

  useEffect(() => {
    setDisabledCode(code.value.length !== 6);
  }, [code.value]);

  useEffect(() => {
    setDisabled(code.status !== "success");
  }, [code.status]);

  useEffect(() => {
    if (open) {
      onSendCode();
    } else {
      setCode({ value: "", status: "initial", error: "" });
      setDisabledCode(true);
      setDisabled(true);
    }
  }, [open]);

  return (
    <Modal open={open} setOpen={setOpen} withClose={code.status !== "success"} withActions={false} onlyCloseButton>
      {code.status === "success" ? (
        <Message variant="success" message="Code verified successfully!" />
      ) : (
        <form className="space-y-6" onSubmit={onSubmitHandler}>
          <CardHeader
            title="Please pass the 2FA to apply the requested change"
            paragraph="*You are about to make a permanent change. This 2FA step is intended to prevent unwanted changes."
          />
          <div className="flex flex-col items-center">
            <InputWithButton
              inputProps={{
                id: "code",
                value: code.value,
                onChange: onChangeHandler,
                placeholder: "Enter SMS code",
                maxLength: 6,
                minLength: 6,
                readOnly: code.status === "success",
              }}
              buttonProps={{
                type: "submit",
                variant: returnButtonVariant(code.status),
                disabled: disabledCode,
                children: returnCodeText,
              }}
            />
            <ValidationMessage>{code.error}</ValidationMessage>
          </div>
        </form>
      )}
    </Modal>
  );
};
