import { useAppDispatch } from "app/hooks";
import { Button, InputWithButton, Sort, Search } from "components";
import { useAdminManagementContext } from "pages/AdminManagement";
import { UserModal } from "pages/AdminManagement/components/UserModal";
import { USER_SORTS, UserSortItem } from "helpers";
import { ChangeEvent, useEffect, useState } from "react";
import { initialUserData, setUserData } from "services/userSlice";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export const AdminHeader = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { onSearch, onSort, params } = useAdminManagementContext();
  const [openModal, setOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState(params.search || "");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1536);

  const translatedUserSorts = USER_SORTS.map((sort) => ({
    ...sort,
    name: t(sort.name),
  }));

  // Dynamically track screen size
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1536);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!openModal) dispatch(setUserData(initialUserData));
  }, [openModal]);

  useEffect(() => {
    setInputValue(params.search || "");
  }, [params.search]);

  return (
    <>
      <div className="flex flex-col gap-3 md:flex-row md:items-center xl:flex-row 2xl:justify-evenly">
        {isSmallScreen ? (
          <div className="w-full">
            <div className="w-6/2 mb-2 flex items-center gap-3">
              <InputWithButton
                inputProps={{
                  placeholder: "Search",
                  variant: "modal",
                  value: inputValue,
                  onChange: (event: ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value),
                  className: "border-primary focus:border-primary flex-1",
                }}
                buttonProps={{
                  children: <Search className="w-10" />,
                  className: "px-6",
                  onClick: () => onSearch(inputValue),
                }}
              />
              <Button onClick={() => setOpenModal(true)} className="w-full uppercase">
                {t("Add User")}
              </Button>
            </div>
            <Sort
              label={t("Sort by:")}
              values={translatedUserSorts}
              onSort={(sort) => onSort(sort as UserSortItem)}
              selectedSort={params.sort}
            />
          </div>
        ) : (
          <>
            <div className="flex w-full flex-col gap-3 xl:flex-row 2xl:w-auto 2xl:flex-row">
              <div className="w-6/12">
                <InputWithButton
                  inputProps={{
                    placeholder: "Search",
                    variant: "modal",
                    value: inputValue,
                    onChange: (event: ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value),
                    className: "border-primary focus:border-primary",
                  }}
                  buttonProps={{
                    children: <Search className="w-10" />,
                    className: "px-6",
                    onClick: () => onSearch(inputValue),
                  }}
                />
              </div>
              <Sort
                label={t("Sort by:")}
                values={translatedUserSorts}
                onSort={(sort) => onSort(sort as UserSortItem)}
                selectedSort={params.sort}
              />
            </div>
            <div>
              <Button onClick={() => setOpenModal(true)} className="w-full uppercase md:w-auto">
                {t("Add New User")}
              </Button>
            </div>
          </>
        )}
      </div>
      {openModal && <UserModal open={openModal} setOpen={setOpenModal} variant="add" />}
    </>
  );
};
