import { User } from "helpers";
import { StatusState } from "pages/AdminManagement/components/AdminTable/components";
import { useAdminManagementContext } from "pages/AdminManagement";
import { Dispatch, SetStateAction } from "react";
import { transformError, useChangeStatusMutation } from "services";
import { InputStyledDiv, Status } from "components";
import { use2FA } from "hooks";

export interface AdminTableDataUserTypeEditProps {
  user: User;
  status: StatusState;
  setStatusState: Dispatch<SetStateAction<StatusState>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}
export const AdminTableDataUserTypeEdit = ({
  user,
  status,
  setStatusState,
  setIsLoading,
}: AdminTableDataUserTypeEditProps) => {
  const { enabled, company_id, uuid } = user;
  const { onFetch } = useAdminManagementContext();
  const { trigger2FA, TwoFAModal } = use2FA();

  const [changeStatus] = useChangeStatusMutation();

  // This function is triggered to verify the 2FA before changing the user's status
  const onStatusChangeVerification = async (company_id: string, id: string, status: boolean) => {
    // Trigger the 2FA modal
    trigger2FA(async (otcode: number) => {
      try {
        if (!otcode) {
          console.error("No 2FA code entered");
          return;
        }

        // Proceed with changing the user's status after successful 2FA verification
        await onStatusChange(company_id, id, status, otcode);
      } catch (error) {
        console.error("Error during 2FA verification:", error);
      }
    });
  };

  // This function is used to change the user's status
  const onStatusChange = async (company_id: string, id: string, status: boolean, otcode: number) => {
    setIsLoading(true);
    try {
      // Send the API request to change the user's status
      await changeStatus({
        company_id,
        uuid: id,
        userStatus: status ? "enable" : "disable",
        otcode: otcode, // Pass the OTP code here
      }).unwrap();

      // Refresh the data
      await onFetch();
    } catch (error) {
      console.error(transformError(error).message);
    } finally {
      setIsLoading(false);
      setStatusState("updated"); // Update the UI to show the updated status
    }
  };

  return (
    <>
      {TwoFAModal}
      <InputStyledDiv className="py-[7px]">
        {status === "initial" && (
          <>
            <span className={enabled ? "text-success" : "text-error"}>{enabled ? "Active" : "Inactive"}</span>
            <div className="flex items-center justify-between">
              <Status />
              <span className="cursor-pointer hover:underline" onClick={() => setStatusState("updating")}>
                <>{enabled ? "Deactivate" : "Activate"}</>
              </span>
            </div>
          </>
        )}
        {status === "updating" && (
          <>
            <span>{enabled ? "Deactivate user ?" : "Activate user ?"}</span>
            <div className="flex items-center justify-between">
              <span
                className="cursor-pointer hover:underline"
                onClick={() => {
                  setStatusState("initial");
                }}
              >
                No
              </span>
              <span
                className="cursor-pointer hover:underline"
                onClick={() => onStatusChangeVerification(company_id, uuid, !enabled)}
              >
                Yes
              </span>
            </div>
          </>
        )}
        {status === "updated" && (
          <>
            <span className={!enabled ? "text-error" : "text-success"}>
              {!enabled ? "User Deactivated" : "User Activated"}
            </span>
            <div className="flex items-center justify-between">
              <Status />
              <span className="cursor-pointer hover:underline" onClick={() => setStatusState("updating")}>
                <>{enabled ? "Deactivate" : "Activate"}</>
              </span>
            </div>
          </>
        )}
      </InputStyledDiv>
    </>
  );
};
