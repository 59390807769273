import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { PlusLarge, Star } from "components";
import { Route } from "helpers";
import { Layout } from "./Layout";
import { useRoleApiPrefetch, useUserApiPrefetch } from "services";
import { useUser } from "hooks";
import { t } from "i18next";

const managementRoutes: Route[] = [
  {
    path: "/admin-management",
    label: "Admin management",
  },
  {
    path: "/user-management",
    label: "User management",
  },
  {
    path: "/company-management",
    label: "Company management",
  },
  {
    path: "/parking-management",
    label: "Parking management",
  },
  {
    path: "/role-management",
    label: "Role management",
  },
];

const reportsRoutes: Route[] = [
  {
    path: "#",
    label: "Sub-option",
  },
  {
    path: "#",
    label: "Sub-option",
  },
];

const monitoringRoutes: Route[] = [
  {
    path: "#",
    label: "Sub-option",
  },
  {
    path: "#",
    label: "Sub-option",
  },
];

export const MainLayout = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();
  const prefetchUsers = useUserApiPrefetch("getUsers");
  const prefetchRolesWithDetails = useRoleApiPrefetch("getRolesWithDetails");

  const translatedManagementRoutes = managementRoutes.map((route) => ({
    ...route,
    label: t(route.label),
  }));

  const handlePrefetch = (path: string) => {
    if (user) {
      if (path === "/admin-management") {
        prefetchUsers({ company_id: user.company_id, page: 0, pageSize: 5 }, { ifOlderThan: 5 });
      }
      if (path === "/role-management") {
        prefetchRolesWithDetails({ company_id: user.company_id, page: 0, pageSize: 4 }, { ifOlderThan: 5 });
      }
    }
  };

  return (
    <Layout
      sidebar={
        <div className="space-y-8">
          <div className="space-y-4">
            <p className="text-xl font-bold">{t("Administration")}</p>
            <div className="space-y-3">
              {translatedManagementRoutes.map(({ path, label }, index) => (
                <NavLink
                  key={index}
                  to={path}
                  className="flex items-center space-x-4"
                  onMouseEnter={() => handlePrefetch(path)}
                >
                  <Star />
                  <p className="text-xl">{label}</p>
                </NavLink>
              ))}
            </div>
          </div>
          <div className="space-y-4">
            <p className="text-xl font-bold">{t("Reports")}</p>
            <div className="space-y-2">
              {reportsRoutes.map(({ path, label }, index) => (
                <div key={index} className="flex items-center space-x-4">
                  <PlusLarge />
                  <p className="text-xl">{label}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="space-y-4">
            <p className="text-xl font-bold">{t("Monitoring")}</p>
            <div className="space-y-2">
              {monitoringRoutes.map(({ label }, index) => (
                <div key={index} className="flex items-center space-x-4">
                  <PlusLarge />
                  <p className="text-xl">{label}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    >
      {children}
    </Layout>
  );
};
